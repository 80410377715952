<h2>Questionários</h2>
status: <select (change)="onFilterStatus($event.target.value)" style="margin-bottom: 20px;padding: 8px 16px; cursor: pointer; border-radius:7px; background-color: #EC8947; color:white">
            <option *ngFor="let i of status">{{i.option}}</option>
        </select>
<form [formGroup]="sixFormGroup">
  <mat-accordion>
    <div *ngFor="let questionario of questionariosFiltered; let i = index; trackBy: trackItem">

      <!-- <mat-checkbox (change)="verify($event.checked, questionarios.idQuestionario)" class="example-margin" formControlName="{{'check-' + i}}"></mat-checkbox> -->
      <mat-expansion-panel style="display: inline-table;width: 100%;">

        <mat-expansion-panel-header>

            <i class="material-icons person">person</i>
            <div class="nomeQuestionarioRead">{{questionario.pessoa.nome}} {{questionario.pessoa.sobrenome}}</div>

         <div class="agenda">
            <div class="infoTr">status: {{questionario.status}} </div>
         </div>

         <!-- <div class="agendamento">
          <button class="botao" mat-button (click)="agendar()" *ngIf="!isAgendado">Agendar</button>
          <button class="botao" mat-button (click)="alterar()" *ngIf="isAgendado">Alerar</button>
          <button class="botao" mat-button (click)="cancelar()">Cancelar</button>
        </div>

        <div>
          <button class="botao" mat-button (click)="avaliar()">Avaliar</button>
        </div> -->

        <div class="navHor">
          <ul>
              <li class="home" *ngIf="!questionario.agendado && questionario.versao === 1">
                <a  class="agendar" (click)="vaiAgendar(questionario.pessoa.idPessoa, questionario.idQuestionario)">
                  <i class="material-icons">calendar_today</i>
                  <b>Agendar</b>
                </a>
              </li>
              <li class="contact" *ngIf="questionario.agendado">
                <a (click)="alterarTriagem(questionario.pessoa.idPessoa, questionario.idQuestionario)"  class="alterar">
                  <i class="material-icons">calendar_today</i>
                  <b>Alterar</b>
                </a>
              </li>
              <li class="contact" *ngIf="questionario.agendado">
                <a (click)="openDialogDeleteTriagem(questionario.idAgendamentoTriagem.idAgendamentoTriagem)"  class="cancelar">
                  <i class="material-icons">cancel_presentation</i>
                  <b>Cancelar</b>
                </a>
              </li>
              <li *ngIf="!questionario.concluido && questionario.agendado" class="projects">
                <a (click)="concluir(questionario.idQuestionario)" class="avaliar">
                  <i class="material-icons">check_circle_outline</i>
                  <b>Concluir</b>
                </a>
                <li *ngIf="questionario.concluido && questionario.agendado" class="contact">
                  <a (click)="retomar(questionario.idQuestionario)" class="alterar">
                    <i class="material-icons">check_circle_outline</i>
                    <b>Retomar</b>
                  </a>
              </li>
              <li *ngIf="questionario.concluido" class="projects">
                <a (click)="encaminhar(questionario.idQuestionario)" class="avaliar">
                  <i class="material-icons">arrow_right_alt</i>
                  <b>Encaminhar</b>
                </a>
              </li>

              <li *ngIf="questionario.concluido" class="segmento" >
                <a  (click)="getSegmentoFinal(questionario.idQuestionario)" class="avaliar">
                  <i class="material-icons">article</i>
                  <b>Seg. Final</b>
                </a>
              </li>

              <li class="about" *ngIf="questionario.versao === 1">
                <a (click)="getQuestionario(questionario.idQuestionario)" class="edit">
                  <i class="material-icons">edit</i>
                  <b>Editar</b>
                </a>
              </li>
              <li class="delete" *ngIf="questionario.versao === 1">
                <a (click)="openDialog(questionario.idQuestionario)"   class="delete">
                  <i class="material-icons">delete</i>
                  <b>Deletar</b>
                </a>
              </li>
          </ul>
      </div>

          <!-- <div class="action">
              <ng-container  matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>Ações</th>

              <td mat-cell>

                <a (click)="agendar()" *ngIf="!isAgendado" class="agendar" (click)="vaiAgendar(questionario.pessoa.idPessoa, questionario.idQuestionario)">
                  <i class="material-icons">
                    calendar_today
                  </i><b>agendar</b>
              </a>

              <a (click)="alterar()" *ngIf="isAgendado" class="alterar">
                <i class="material-icons">
                  calendar_today
                </i><b>alterar</b>
            </a>
            <a (click)="editAgendamentoTriagem(questionario.idQuestionario)" class="cancelar">
              <i class="material-icons">
                cancel_presentation
              </i><b>cancelar</b>
          </a>
          <a (click)="avaliar()" class="avaliar">
            <i class="material-icons">
              check_circle_outline
            </i><b>avaliar</b>
        </a>
                <a (click)="getQuestionario(questionario.idQuestionario)" class="edit">
                  <i class="material-icons">
                      edit
                  </i><b>edit</b>
              </a>
              <a (click)="openDialog(questionario.idQuestionario)"   class="delete">
                  <i class="material-icons">
                      delete
                  </i><b>delete</b>
              </a>
              </td>
            </ng-container>
    </div> -->

        </mat-expansion-panel-header>



        <div *ngIf="questionario.idAgendamentoTriagem">
          <h3>Agendamento de Triagem</h3>
          <div class="retangulo">
            <table>
              <tr>
                <th>Terapeuta</th>
                <th>Dia da Semana</th>
                <th>Dia</th>
                <th>Hora</th>
              </tr>
              <tr>
                <td>{{questionario.idAgendamentoTriagem.terapeuta.nome}} {{questionario.idAgendamentoTriagem.terapeuta.sobrenome}}</td>
                <td>{{questionario.idAgendamentoTriagem.agenda.diaSemana.descricao}}</td>
                <td>{{questionario.idAgendamentoTriagem.diaMes}} / {{questionario.idAgendamentoTriagem.mes}}</td>
                <td>{{questionario.idAgendamentoTriagem.agenda.horaInicio}}</td>
              </tr>
            </table>
          </div>
        </div>




    <div>
        <h3>Dados Pessoais</h3>
        <div class="retangulo">
          <table>
            <tr>
              <th>Id</th>
              <th>Versão</th>
              <th>Cpf</th>
              <th>Telefone</th>
            </tr>
            <tr>
              <td>{{questionario.idQuestionario}}</td>
              <td>V.{{questionario.versao}}</td>
              <td>{{questionario.pessoa.cpf}}</td>
              <td>{{questionario.pessoa.telefone}}</td>
            </tr>
          </table>
          <table >
            <tr>
              <th>E-mail</th>
              <th>Escolaridade</th>
              <th>Nascimento</th>
              <th>Data Cadastro</th>
            </tr>
            <tr>
              <td>{{questionario.pessoa.email}}</td>
              <td>{{questionario.pessoa.escolaridade.escolaridade}}</td>
              <td>{{questionario.pessoa.dataNasc}}</td>
              <td>{{questionario.pessoa.createdAt | slice:0:10}}</td>
            </tr>
          </table>
        </div>
      </div>



      <div>
        <h3>Endereço</h3>
        <div class="retangulo" >
          <table>
            <tr>
              <th>Cep</th>
              <th>Logradouro</th>
              <th>Numero</th>
            </tr>
            <tr>
              <td>{{questionario.pessoa.endereco.cep}}</td>
              <td>{{questionario.pessoa.endereco.logradouro}}</td>
              <td>{{questionario.pessoa.endereco.numeroR}}</td>
            </tr>
          </table>
          <table >
            <tr>
              <th>Complemento</th>
              <th>Bairro</th>
              <th>Estado</th>
            </tr>
            <tr>
              <td>{{questionario.pessoa.endereco.complemento}}</td>
              <td>{{questionario.pessoa.endereco.bairro}}</td>
              <td>{{questionario.pessoa.endereco.estado}}</td>
            </tr>
          </table>
          <table >
            <tr>
              <th>Cidade</th>
            </tr>
            <tr>
              <td>{{questionario.pessoa.endereco.cidade}}</td>
            </tr>
          </table>
        </div>
      </div>


      <div>
        <h3>Questionário</h3>
        <div class="retangulo" >
          <table>
            <tr>
              <th>O que te traz à terapia e porque nesse momento?</th>
              <th>O que imagina transformar e ou construit na sua vida com esse processo?</th>
            </tr>
            <tr>
              <td>{{questionario.trazTerapia.trazTerapia}}</td>
              <td>{{questionario.imagina}}</td>
            </tr>
          </table>

          <table>
            <tr>
              <th>Descritivo de o que traz à terapia</th>
            </tr>
            <tr>
              <td>{{questionario.trazTerapiaDesc}}</td>
            </tr>
          </table>

          <table >
            <tr>
              <th>Você já fez terapia?</th>
              <th>Tomou ou toma alguma medicação psiquiátrica?</th>
            </tr>
            <tr>
              <td><span *ngIf="questionario.fazTerapia != null">{{questionario.fazTerapia ?'sim':'não'}}</span></td>
              <td><span *ngIf="questionario.tomaRemedio != null">{{questionario.tomaRemedio ?'sim':'não'}}</span></td>
            </tr>
          </table>

          <table>
            <tr>
              <th>Descritivo de por quanto tempo fez terapia</th>
            </tr>
            <tr>
              <td>{{questionario.fazTerapiaDesc}}</td>
            </tr>
          </table>

          <table>
            <tr>
              <th>Quais são suas ocupações atualmente?</th>
              <th>Como você veria sua consição financeira nesse momento?</th>
            </tr>
            <tr>
              <td>{{questionario.ocupacoes}}</td>
              <td>{{questionario.condicaoFinanceira.condicaoFinanceira}}</td>
            </tr>
          </table>
          <table>
            <tr>
              <th>Como você veria sua relação com dinheiro?</th>
              <th>Você colabora financeiramente com sua família?</th>
            </tr>
            <tr>
              <td>{{questionario.relacaoDinheiro.relacaoDinheiro}}</td>
              <td><span *ngIf="questionario.colaboraFamilia != null">{{questionario.colaboraFamilia ?'sim':'não'}}</span></td>
            </tr>
          </table>
          <table>
            <tr>
              <th>Quantas pessoas residem com você?</th>
              <th>Descreva as pessoas que residem com você?</th>
            </tr>
            <tr>
              <td>{{questionario.pessoasResidem}}</td>
              <td>{{questionario.pessoasResidemDescritivo}}</td>
            </tr>
          </table>
          <table>
            <tr>
              <th>O que você faz para se divertir, tem algum hobbie?</th>
              <th>Descreva seus hobbies e o que faz para se divertir?</th>
            </tr>
            <tr>
              <td><span *ngIf="questionario.temHobbie != null">{{questionario.temHobbie ?'sim':'não'}}</span></td>
              <td>{{questionario.diversaoHobbieDescritivo}}</td>
            </tr>
          </table>
          <table>
            <tr>
              <th>Costuma estudar, fazer cursos?</th>
              <th>Descreva seus estudos e cursos</th>
            </tr>
            <tr>
              <td><span *ngIf="questionario.estuda != null">{{questionario.estuda ?'sim':'não'}}</span></td>
              <td>{{questionario.estudaCursosDescritivo}}</td>
            </tr>
          </table>
          <table>
            <tr>
              <th>Como estão seus sonhos para a vida?</th>
              <th>Porque escolheu a TBI?</th>
            </tr>
            <tr>
              <td>{{questionario.sonhos}}</td>
              <td>{{questionario.porqueTbi.porqueTbi}}</td>
            </tr>
          </table>
          <table>
            <tr>
              <th>Existe algum fator de tensão em sua vida</th>
              <th>Alguma perda significativa na sua vida?</th>
            </tr>
            <tr>
              <td>{{questionario.fatorTensao.fatorTensao}}</td>
              <td><span *ngIf="questionario.perda != null">{{questionario.perda ?'sim':'não'}}</span></td>
            </tr>
          </table>
          <table>
            <tr>
              <th>Descreva esta perda.</th>
              <th>Quanto está disposto à investir na terapia?</th>
            </tr>
            <tr>
              <td>{{questionario.perdaDescritivo}}</td>
              <td><span *ngIf="questionario.investirAte !=0 || questionario.investirAte != 0.0">{{questionario.investirAte}}</span></td>
            </tr>
          </table>
          <table>
            <tr>
              <th>Qual o bairro de preferência para a terapia?</th>
              <!-- <th>Impressão terapeura:</th> -->
            </tr>
            <tr>
              <td>{{questionario.bairroPreferencia}}</td>
              <!-- <td>{{questionario.impressaoTerapeuta}}</td> -->
            </tr>
          </table>
          <table>
            <tr>
              <th>Dia da Semana preferido para Triagem:</th>
              <th>Período preferido para Triagem:</th>
            </tr>
            <tr>
              <td>{{questionario.diaSemana}}</td>
              <td>{{questionario.periodo}}</td>
            </tr>
          </table>
        </div>
      </div>





</mat-expansion-panel>
    </div>
  </mat-accordion>

</form>
<mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="5" [length]="totalElements" (page)="nextPage($event)"> </mat-paginator>






<!--

<div class="mat-elevation-z4">
  <table mat-table [dataSource]="questionarios">
    <ng-container matColumnDef="idQuestionario">
      <th mat-header-cell *matHeaderCellDef>Id</th>
      <td mat-cell *matCellDef="let row">{{row.idQuestionario}}</td>
    </ng-container>

    <ng-container matColumnDef="pessoa.cpf">
      <th mat-header-cell *matHeaderCellDef>Cpf</th>
      <td mat-cell *matCellDef="let row">{{row.pessoa.cpf}}</td>
    </ng-container>

    <ng-container matColumnDef="pessoa.nome">
      <th mat-header-cell *matHeaderCellDef>Nome</th>
      <td mat-cell *matCellDef="let row">{{ row.pessoa.nome}}</td>
    </ng-container>

       <ng-container matColumnDef="pessoa.sobrenome">
        <th mat-header-cell *matHeaderCellDef>Sobre nome</th>
        <td mat-cell *matCellDef="let row">{{ row.pessoa.sobrenome}}</td>
      </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>Ações</th>
      <td mat-cell *matCellDef="let row">
          <a (click)="getQuestionario(row.idQuestionario)" class="edit">
              <i class="material-icons">
                  edit
              </i>
          </a>
          <a (click)="openDialog(row.idQuestionario)"   class="delete">
              <i class="material-icons">
                  delete
              </i>
          </a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div> -->
