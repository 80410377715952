import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { Psicologo } from 'src/app/psicologo/psicologo.model';
import { PsicologoService } from 'src/app/psicologo/psicologo.service';
import { TerapiaService } from '../terapia.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Terapia } from '../terapia.model';
import { ActivatedRoute, Router } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-terapia-read',
  templateUrl: './terapia-read.component.html',
  styleUrls: ['./terapia-read.component.css']
})
export class TerapiaReadComponent implements OnInit {

  usuarioLogado: string;
  roles: string;
  id:number;
  ehAdmin: boolean = false;
  public pessoa = new Psicologo;
  terapias: Array<any> = [  ];
  public terapiaFormGroup: FormGroup;
  public terapiasFiltered: Array<Terapia> = Array<Terapia>();
  totalElements: number = 0;


  constructor(private formBuilder: FormBuilder, public psicologoService: PsicologoService,
                    private authService: AuthService, private terapiaService: TerapiaService,
                    private route: ActivatedRoute, public dialog: MatDialog, public router: Router) {
    this.terapiaFormGroup = this.formBuilder.group({});
  }

  ngOnInit(): void {
    this.usuarioLogado = this.authService.getUsuarioAutenticado();
    if (this.route.snapshot.paramMap.get('id') && !this.pegaRoles()){
      this.onListarTerapiaTerapeuta(Number(this.route.snapshot.paramMap.get('id')));
    }else {
      this.onListarTerapia({ page: "0", size: "5" });
    }
  }

  public onListarTerapiaTerapeuta(id): void{
    this.terapiaService.getTerapiasTerapeuta(id).subscribe(resultado => {
      this.terapias = resultado,
      this.terapiasFiltered = this.terapias;
    });
    // console.log(this.questionarios);
  }

  public onListarTerapia(request): void{
    this.terapiaService.getTerapias(request).subscribe(resultado => {
      this.terapias = resultado['content'],
      this.terapiasFiltered = this.terapias,
      this.totalElements = resultado['totalElements'];
      // this.questionariosInicial = resultado['content'];
      // console.log(this.terapias);

    });

  }


  pegaRoles(){
    this.roles = this.authService.obterRoles();
    if(this.roles == "ROLE_ADMIN"){
      this.ehAdmin = true;
      return true;
    }
    return false;
  }

  public trackItem (index: number, item: Terapia) {
    return item.idTerapia;
  }

  nextPage(event: PageEvent) {
    const request = {};
    request['page'] = event.pageIndex.toString();
    request['size'] = event.pageSize.toString();
    this.onListarTerapia(request);
  }

  openDialogDeleteTerapia(id: number): void {
    this.terapiaService.idDel = id;
    const dialogRef = this.dialog.open(DialogTerapiaDeleteComponent, {
      height: '250px',
      width: '400px',});

    dialogRef.afterClosed().subscribe(result => {
      // console.log(`Dialog result: ${result}`);
    });
  }

  concluir(id: Number){
    this.terapiaService.concluir(id).subscribe(resultado => {
      window.location.reload();
    });
  }

  retomar(id: Number){
    this.terapiaService.retomar(id).subscribe(resultado => {
      window.location.reload();
    });
  }

  getTerapia(id): void{
    this.router.navigate(['/terapia/editar/' + id]);
  }

  getRelatorio(id) {
    this.router.navigate(['/terapia/relatorio/' + id]);
  }

  getSegmento1(id) {
    this.router.navigate(['/terapia/segmento1/' + id]);
  }

  getSegmento2(id) {
    this.router.navigate(['/terapia/segmento2/' + id]);
  }
}


@Component({
  selector: 'dialog-terapia-delete-component',
  templateUrl: 'dialog-terapia-delete-component.html',
})
export class DialogTerapiaDeleteComponent implements OnInit{


  terapia: Terapia;
  id: number = this.terapiaService.idDel;

  constructor(private terapiaService: TerapiaService) { }

  ngOnInit(): void {
    this.terapiaService.readById(this.id).subscribe((terapia) => {
      this.terapia = terapia;
    });

  }

  delete(): void  {
    this.terapiaService.deleteTerapia(this.terapiaService.idDel).subscribe(
      () => this.terapiaService.getTerapias({ page: "0", size: "5" }));
  }
}
